import mutations from './mutations';
import { checkResponse, mustChangePass } from '@/util/common-utils';
import router from '@/routes/protectedRoute';
import { DataService } from '@/dataService/dataService';
import { showConfirm } from '@/util/confirm';
import { notification } from 'ant-design-vue';
import ConstantAPI from '@/config/ConstantAPI';
import Cookies from 'js-cookie';

function getPathOfFirstObject(tree) {
  if (tree && Array.isArray(tree) && tree.length > 0) {
    const firstItem = tree[0];
    if (firstItem.children === null) {
      return firstItem.path;
    } else {
      return getPathOfFirstObject(firstItem.children);
    }
  }
  return '/settings/profile-settings/profile';
}

const state = () => ({
  userInfo: { allMenu: JSON.parse(localStorage.getItem('allMenu') || null) },
  notifications: [],
  loading: false,
  uploading: false,
  error: null,
  api: ConstantAPI.auth,
  page: -1,
  isContinue: true,
  token: Cookies.get('token'),
  errMess: '',
  captcha: null,
});

const actions = {
  setMessage({ commit }, data) {
    commit('loginErr', data);
  },
  async login({ commit, state }, data) {
    try {
      commit('loginBegin', true);
      const payload = {
        ...data,
        grant_type: 'password',
      };
      let response = await DataService.callApi(
        state.api.LOGIN,
        new URLSearchParams(payload),
        null,
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      );
      if (response) {
        checkResponse(
          response,
          async () => {
            response = await DataService.callApi(
              ConstantAPI.user.GET_USER_INFO
            );
            mustChangePass(response.data);

            // lấy ra path url đầu tiên của user để truy cập vào đó
            const path = getPathOfFirstObject(response.data.menus);
            router.push(path);

            return commit('getUserInfoSuccess', response.data);
          },
          () => {
            state.errMess = response.message;
            commit('loginErr', response.message);
          }
        );
        // commit('setToken', response.data.accessToken);
        // Cookies.set('token', response.data.accessToken);
      }
    } catch (err) {
      console.log(err);
      commit(
        'loginErr',
        state.errMess || 'Tên tài khoản hoặc mật khẩu không đúng'
      );
    }
  },
  async logOut({ commit }) {
    try {
      localStorage.removeItem('menus');
      await DataService.callApi(ConstantAPI.auth.LOGOUT);
    } catch (err) {
      commit('logoutErr', err);
    } finally {
      if (window.location.pathname !== '/manager/private-policy') {
        await router.push('/auth/login');
        commit('logoutSuccess');
      }
    }
  },
  async getUserInfo({ commit }) {
    const response = await DataService.callApi(ConstantAPI.user.GET_USER_INFO);
    mustChangePass(response.data);
    commit('getUserInfoSuccess', response.data);
  },
  async getNotifications({ state, commit }) {
    state.page += 1;
    const params = {
      page: state.page,
      size: 10,
    };
    const notification = await DataService.callApi(
      ConstantAPI.user.GET_NOTIFICATIONS,
      null,
      params
    );
    if (notification.data.content === null) commit('setContinue', false);
    if (notification.data && notification.data.content) {
      commit('setNotifications', notification.data.content);
    }
  },

  async markReadNoti({ commit }, id) {
    try {
      const response = await DataService.patch(
        `${ConstantAPI.user.MARK_READ_NOTIFICATION.url}/${id}`
      );

      checkResponse(
        response,
        () => {
          commit('markReadNoti', id);
        },
        () => {}
      );
    } catch (error) {
      console.log(error);
    }
  },
  async changePassword({ commit, state, dispatch }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn đổi mật khẩu không?')) {
        commit('setLoading', true);
        const data = {
          id: state.userInfo.id,
          newPassword: payload.newPassword,
          oldPassword: payload.oldPassword,
        };
        const response = await DataService.callApi(
          state.api.PATCH_UPDATE,
          data
        );
        checkResponse(response, () => {
          notification.success({
            message: 'Thông báo',
            description: 'Thay đổi mật khẩu thành công',
            duration: 4,
          });
          dispatch('logOut')
        });
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateProfile({ commit }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        payload.userROles = null;
        const response = await DataService.callApi(
          ConstantAPI.user.PATCH_UPDATE,
          payload,
          null
        );
        checkResponse(response, async () => {
          notification.success({
            message: 'Thông báo',
            description: 'Cập nhật thông tin thành công',
            duration: 4,
          });
          const response = await DataService.callApi(
            ConstantAPI.user.GET_USER_INFO
          );
          commit('getUserInfoSuccess', response.data);
        });
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async changeAvatar({ commit, state }, file) {
    try {
      commit('setUpLoading', true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('rootPath', 'avatar');
      let response = await DataService.callApi(
        ConstantAPI.upload_service.UPLOAD,
        formData,
        null,
        { 'Content-Type': 'multipart/form-data' }
      );
      checkResponse(response, async () => {
        response = await DataService.callApi(
          ConstantAPI.user.PATCH_UPDATE,
          { id: state.userInfo.id, avatar: response.data.url },
          null
        );
        checkResponse(response, async () => {
          notification.success({
            message: 'Thông báo',
            description: 'Thay đổi avatar thành công',
            duration: 4,
          });
          state.userInfo = {
            ...state.userInfo,
            avatar: response.data.avatar,
          };
          commit('setUpLoading', false);
        });
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      const response = await DataService.get(ConstantAPI.user.GET_USER_INFO);
      commit('getUserInfoSuccess', response.data);
      commit('setUpLoading', false);
    }
  },
  async getCaptcha({commit, state}) {
    try {
      commit('setLoading', true)
      const response = await DataService.callApi(ConstantAPI.user.GET_CAPTCHA)
      checkResponse(response, async () => {
        state.captcha = response.data
        commit('setLoading', false);
      });
    } catch (err) {
      console.log(err)
    } finally {
      commit('setLoading', false)
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters: {
    accessToken: (state) =>
      state.tokenInfo ? state.tokenInfo.access_token : null,
    menus: (state) => (state.userInfo ? state.userInfo.menus : []),
    allMenu: (state) => (state.userInfo || {}).allMenu || [],
    loading: (state) => state.loading,
    uploading: (state) => state.uploading,
  },
};
