<template>
  <div class="align-center">
    <a-tooltip title="Xem" v-if="hasView">
      <a-button
        type="ghost"
        shape="circle"
        @click="emitEvent('onView')"
        class="text-white bg-[#6D9D02] mr-5 hover:bg-[#6D9D02] focus:bg-[#6D9D02]"
      >
        <template #icon>
          <cds-feather-icons type="eye" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Sửa" v-if="hasEdit">
      <a-button
        type="primary"
        :disabled="disabledEdit"
        shape="circle"
        class="text-white mr-5 bg-evisa-blue hover:bg-evisa-blue"
        @click="emitEvent('onEdit')"
      >
        <template #icon>
          <cds-feather-icons type="edit-3" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Hủy" v-if="hasCancelApproval && isShowApproval">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #ff4d4f"
        shape="circle"
        @click="emitEvent('onCancelApproval')"
      >
        <template #icon>
          <cds-feather-icons type="x" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Hiển thị" v-if="hasShow && !isShow">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #20c997"
        shape="circle"
        @click="emitEvent('onShow')"
      >
        <template #icon>
          <cds-feather-icons type="eye" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Ẩn" v-if="hasHidden && isShow">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #ff4d4f"
        shape="circle"
        @click="emitEvent('onHidden')"
      >
        <template #icon>
          <cds-feather-icons type="eye-off" />
        </template>
      </a-button>
    </a-tooltip>

    <a-tooltip title="Duyệt" v-if="hasApproval">
      <a-button
        type="primary"
        style="color: #fff"
        shape="circle"
        :disabled="disabledApproval"
        class="text-white bg-[#20c997] mr-5 hover:bg-[#20c997] focus:bg-[#20c997]"
        @click="emitEvent('onApproval')"
      >
        <template #icon>
          <cds-feather-icons type="check" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Biên tập lại" v-if="hasReEdit">
      <a-button
        type="primary"
        style="color: #ff4d4f"
        shape="circle"
        :disabled="disabledReEdit"
        class="text-white bg-[#ff4d4f] mr-5 hover:bg-[#ff4d4f] focus:bg-[#ff4d4f]"
        @click="emitEvent('onReEdit')"
      >
        <template #icon>
          <cds-feather-icons type="x" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Xuất bản" v-if="hasPublish">
      <a-button
        type="primary"
        style="color: #fff"
        shape="circle"
        :disabled="disabledPublish"
        class="text-white bg-[#20c997] mr-5 hover:bg-[#20c997] focus:bg-[#20c997]"
        @click="emitEvent('onPublish')"
      >
        <template #icon>
          <cds-feather-icons type="check" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Duyệt lại" v-if="hasRePublish">
      <a-button
        type="primary"
        style="color: #fff"
        shape="circle"
        :disabled="disabledRePublish"
        class="text-white bg-[#ff4d4f] mr-5 hover:bg-[#ff4d4f] focus:bg-[#ff4d4f]"
        @click="emitEvent('onReApproval')"
      >
        <template #icon>
          <cds-feather-icons type="x" />
        </template>
      </a-button>
    </a-tooltip>

    <a-tooltip title="Xóa" v-if="hasDelete">
      <a-button
        type="ghost"
        style="color: #ff4d4f"
        shape="circle"
        @click="emitEvent('onDelete')"
      >
        <template #icon>
          <cds-feather-icons type="trash" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Đặt lại mật khẩu" v-if="hasResetPassword">
      <a-button
          type="primary"
          shape="circle"
          style="color: #C5A136"
          class="text-white mr-5 bg-[#C5A136] hover:bg-[#C5A136] focus:bg-[#C5A136]"
          @click="emitEvent('onResetPassword')"
      >
        <template #icon>
          <cds-feather-icons type="refresh-ccw" />
        </template>
      </a-button>
    </a-tooltip>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ActionButton',
  props: {
    // {type, permission}
    buttons: VueTypes.array.def([]),
    disabledEdit: VueTypes.bool.def(false),
    disabledApproval: VueTypes.bool.def(false),
    disabledReEdit: VueTypes.bool.def(false),
    disabledPublish: VueTypes.bool.def(false),
    disabledRePublish: VueTypes.bool.def(false),
    isShowApproval: VueTypes.bool.def(false),
    isShow: VueTypes.bool.def(false),
  },
  setup(props, { emit }) {
    const emitEvent = (event) => {
      emit(event);
    };
    const { state } = useStore();
    const permissions = computed(() => state.auth.userInfo.permissions || []);
    const hasButton = (button) =>
      props.buttons.some(
        (e) =>
          e.type === button &&
          (!e.permission || permissions.value.includes(e.permission))
      );
    return {
      emitEvent,
      hasEdit: hasButton('EDIT'),
      hasView: hasButton('VIEW'),
      hasDelete: hasButton('DELETE'),
      hasReEdit: hasButton('RE-EDIT'),
      hasApproval: hasButton('APPROVAL'),
      hasRePublish: hasButton('RE_PUBLISH'),
      hasPublish: hasButton('PUBLISH'),
      hasCancelApproval: hasButton('CANCEL_APPROVAL'),
      hasShow: hasButton('SHOW'),
      hasHidden: hasButton('HIDDEN'),
      hasResetPassword: hasButton('RESET_PASSWORD')
    };
  },
});
</script>

<style scoped></style>
