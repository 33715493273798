import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {checkResponse} from "@/util/common-utils";
import {notification} from "ant-design-vue";

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    api: ConstantAPI.searchProfilesSync,
    visible: false,
});

const actions = {
    async genFile({state}, maSoHoso) {
        try {
            const response = await DataService.callApi(
                state.api.GEN_FILE, {maSoHoso}
            );
            checkResponse(response, () => {
                notification.success({
                    message: 'Thông báo',
                    description: 'Tạo file thành công',
                    duration: 4,
                });
            });
        } catch (err) {
            console.log(err);
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        updateField,
    },
    getters: {
        getField,
    },
};
