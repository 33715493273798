export const FORM_MODE = {
    CREATE: 1,
    UPDATE: 2,
    VIEW: 3,
};

export const GENDERS = [
    {
        value: 1,
        label: 'Nam',
    },
    {
        value: 0,
        label: 'Nữ',
    },
    {
        value: 2,
        label: 'Khác',
    },
];

export const TYPES_HOTLINE_OBJ = {
    1: 'Bản quản lý toàn nhà',
    2: 'Lực lượng phòng cháy',
};

export const TYPES_HOTLINE = [
    {
        value: 1,
        label: TYPES_HOTLINE_OBJ[1],
    },
    {
        value: 2,
        label: TYPES_HOTLINE_OBJ[2],
    },
];

export const STATUS = [
    {
        value: 1,
        label: 'Hoạt động',
    },
    {
        value: 0,
        label: 'Không hoạt động',
    },
];
export const LIST_STATUS_SHOW = [
    {
        value: 1,
        label: 'Hiển thị',
    },
    {
        value: 0,
        label: 'Không hiển thị',
    },
];

export const OBJECT_QA = [
    {
        value: 1,
        label: 'Cá nhân',
    },
    {
        value: 2,
        label: 'Tổ chức',
    },
    {
        value: 3,
        label: 'Tổng hợp',
    },
];
export const STATUS_APPROVAL = [
    {
        value: 1,
        label: 'Đã duyệt',
    },
    {
        value: 0,
        label: 'Chưa duyệt',
    },
    {
        value: -1,
        label: 'Từ chối',
    },
];

export const ARTICLE_STATUS_BAN_NHAP = 1;
export const ARTICLE_STATUS_GUI_DUYET = 2;
export const ARTICLE_STATUS_BIEN_TAP_LAI = 3;
export const ARTICLE_STATUS_CHO_XUAT_BAN = 4;
export const ARTICLE_STATUS_DUYET_LAI = 5;
export const ARTICLE_STATUS_XUAT_BAN = 6;
export const ARTICLE_STATUS_GO_BAI = 7;
export const STATUS_ARTICLE = [
    {
        value: ARTICLE_STATUS_BAN_NHAP,
        label: 'Bản nháp',
    },
    {
        value: ARTICLE_STATUS_GUI_DUYET,
        label: 'Gửi duyệt',
    },
    {
        value: ARTICLE_STATUS_BIEN_TAP_LAI,
        label: 'Biên tập lại ',
    },
    {
        value: ARTICLE_STATUS_CHO_XUAT_BAN,
        label: 'Chờ xuất bản',
    },
    {
        value: ARTICLE_STATUS_DUYET_LAI,
        label: 'Duyệt lại',
    },
    {
        value: ARTICLE_STATUS_XUAT_BAN,
        label: 'Xuất bản',
    },
    {
        value: ARTICLE_STATUS_GO_BAI,
        label: 'Gỡ bài',
    },
];
export const STATUS_ARTICLE_COLOR = [
    {
        value: ARTICLE_STATUS_BAN_NHAP,
        label: '#9196a4',
    },
    {
        value: ARTICLE_STATUS_GUI_DUYET,
        label: '#dcd724',
    },
    {
        value: ARTICLE_STATUS_BIEN_TAP_LAI,
        label: '#d966a0',
    },
    {
        value: ARTICLE_STATUS_CHO_XUAT_BAN,
        label: '#6941a4',
    },
    {
        value: ARTICLE_STATUS_DUYET_LAI,
        label: '#d0095a',
    },
    {
        value: ARTICLE_STATUS_XUAT_BAN,
        label: '#269322',
    },
    {
        value: ARTICLE_STATUS_GO_BAI,
        label: '#ccbfb1',
    },
];

export const AGENCY_TYPE = [
    {
        value: 1,
        label: 'Cơ quan',
    },
    {
        value: 2,
        label: 'Nhà mạng',
    },
    {
        value: 3,
        label: 'Bank',
    },
];

export const AGENCY_PROCESS = [
    {
        value: 0,
        label: 'Chưa có thông tin',
    },
    {
        value: 1,
        label: 'Chưa thực hiện',
    },
    {
        value: 2,
        label: 'Đang thực hiện',
    },
    {
        value: 3,
        label: 'Đã thực hiện',
    },
];

export const defaultButton = (menu) => {
    const buttonsForm = [
        {type: 'RESET'},
        {type: 'SEARCH'},
        {type: 'CREATE', permission: `${menu}:create`},
        {type: 'DELETE', permission: `${menu}:delete`},
        {type: 'IMPORT', permission: `${menu}:import`},
        {type: 'REASSIGNMENT', permission: `${menu}:reassignment`},
        {type: 'APPROVAL', permission: `${menu}:approval`},
        {type: 'SIGN', permission: `${menu}:sign`},
        {type: 'VISA_CANCEL', permission: `${menu}:visa-cancel`},
        {type: 'PROPOSE', permission: `${menu}:propose`},
        {type: 'PRINT', permission: `${menu}:print`},
        {type: 'EXPORT_EXCEL', permission: `${menu}:export-excel`},
        {type: 'EXPORT_PDF', permission: `${menu}:export-pdf`},
        {type: 'EXPORT_PDF', permission: `${menu}:export`},
        {type: 'FAST_PROPOSE', permission: `${menu}:fast-propose`},
    ];
    const buttonActions = [
        {type: 'EDIT', permission: `${menu}:edit`},
        {type: 'RESET_PASSWORD', permission: `${menu}:reset-password`},
        {type: 'VIEW'},
    ];
    return {buttonsForm, buttonActions};
};

export const defaultButtonApproval = () => {
    const buttonsForm = [{type: 'RESET'}, {type: 'SEARCH'}];
    const buttonActions = [{type: 'APPROVAL'}, {type: 'CANCEL_APPROVAL'}];
    return {buttonsForm, buttonActions};
};
export const defaultButtonConfigAgency = () => {
    const buttonsForm = [{type: 'RESET'}, {type: 'SEARCH'}];
    const buttonActions = [{type: 'SHOW'}, {type: 'HIDDEN'}];
    return {buttonsForm, buttonActions};
};
export const HIDDEN_MENUS = ['/system/agency/detail'];
export const STATUS_AGENCY = {
    show: 1,
    hidden: 0,
};
export const STATUS_AGENCY_TEXT = [
    {
        value: 1,
        label: 'Hiển thị',
    },
    {
        value: 0,
        label: 'Ẩn',
    },
];

export const TYPE_BANNER = [
    {
        value: 1,
        label: 'Đầu trang',
    },
    {
        value: 2,
        label: 'Thân trang',
    },
];

export const TYPE_AGENCY = [
    {
        value: 1,
        label: 'Cơ quan ban hành',
    },
    {
        value: 2,
        label: 'Cơ quan tiếp nhận',
    },
];

export const TOPICS = [
    {label: 'Tin tức chung', value: 1},
    {label: 'Hướng dẫn', value: 2},
    {label: 'Ban quản trị', value: 3},
];

export const ARTICLE_STATUS = [
    {label: 'Ẩn', value: 0},
    {label: 'Hiển thị', value: 1},
];

export const ACTION_IMPORT = {
    RESIDENT: 'resident',
    BUILDING_MANAGER: 'buildingManager',
};

export const CONTACT_STATUS = [
    {label: 'Chưa lưu', value: 0},
    {label: 'Đã lưu, chưa mail', value: 1},
    {label: 'Đã mail', value: 2}
];

export const REQUEST_TYPE = [
    {label: 'Điền sai thông tin tờ khai', value: "1"},
    {label: 'Thanh toán không thành công', value: "2"},
    {label: 'Không nhớ mã số hồ sơ', value: "3"},
    {label: 'Khác', value: "4"},
]


export const REGEX_NO_SPECIAL_CHARACTER =
    /^[^*|%!~":<>\\[\]{}`\\(\\)\\';@&\\$+^#/?=,_-]+$/;

export const REGEX_ENGLISH_ONLY = /^[a-zA-Z0-9. ]*$/;

export const UNIT_LEVEL = [
    {label: 'Cấp phòng', value: 'CAP_PHONG'},
    {label: 'Cấp cục', value: 'CAP_CUC'},
];

export const DOI_TUONG = [
    {label: 'Đối tượng', value: 1},
    {label: 'Vi phạm pháp luật', value: 2},
    {label: 'Giấy tờ hủy', value: 3},
    {label: 'Chưa xuất cảnh', value: 4},
];

export const TINH_TRANG = [
    {label: 'Hồ sơ mới', value: 'PC'},
    {label: 'Đã đề xuất', value: 'DX'},
    {label: 'Xử lý theo chỉ thị', value: 'XL_TCT'},
    {label: 'Yêu cầu bổ sung', value: 'YC_BS'},
    {label: 'Tiếp nhận bổ sung', value: 'TN_BS'},
    {label: 'Xin ý kiến', value: 'XYK'},
];

export const TINH_TRANG_DUYET = [
    {label: 'Hồ sơ đề xuất', value: 'DX'},
    {label: 'Hồ sơ xin ý kiến', value: 'XYK'},
];

export const TINH_TRANG_TEXT = [
    {label: 'Hồ sơ mới', value: 'PC'},
    {label: 'Đã đề xuất', value: 'DX'},
    {label: 'Xử lý theo chỉ thị', value: 'XL_TCT'},
    {label: 'Yêu cầu bổ sung', value: 'YC_BS'},
    {label: 'Tiếp nhận bổ sung', value: 'TN_BS'},
    {label: 'Xin ý kiến', value: 'XYK'},
    {label: 'Đã đề xuất', value: 'DX_TC_TT'},
    {label: 'Đã đề xuất', value: 'DX_CAP_TT'},
];

export const TRANG_THAI_HS = [
    {label: 'Hồ sơ mới', value: 1},
    {label: 'Sắp hết hạn', value: 2},
    {label: 'Đã quá hạn', value: 3},
];
export const DE_XUAT = [
    {label: 'Đề xuất', value: 0},
    {label: 'Xin ý kiến lãnh đạo', value: 1},
];
export const DE_XUAT_VALUE = {
    DE_XUAT: 0,
    XIN_Y_KIEN: 1,
};
export const DE_NGHI = [
    {label: 'Một lần', value: 0},
    {label: 'Nhiều lần', value: 1},
];
export const DE_NGHI_VALUE = {
    MOT_LAN: 0,
    NHIEU_LAN: 1,
};

export const TINH_TRANG_VALUE = {
    HO_SO_MOI: 'PC',
    DA_DE_XUAT: 'DX',
    XU_LY_TCT: 'XL_TCT',
    YEU_CAU_BO_SUNG: 'YC_BS',
    TIEP_NHAN_BO_SUNG: 'TN_BS',
    XIN_Y_KIEN: 'XYK',
};

export const SITE = [
    {label: 'Hà Nội', value: 'BAC'},
    {label: 'Hồ Chí Minh', value: 'NAM'},
];

export const TIME_COMPARE = {
    WARNING: 2,
    OUT_OF_DATE: 3,
};

export const CHTC_OPTIONS = {
    chiaDeu: {
        value: 1,
        label: 'Chia đều hồ sơ',
    },
    soLuong: {
        value: 4,
        label: 'Chia theo số lượng',
    },
    nhan: {
        value: 3,
        label: 'Chia theo nhãn',
    },
    trongSo: {
        value: 2,
        label: 'Chia theo trọng số',
    },
};

export const KET_QUA_HO_SO = {
    CHAP_THUAN: 'Chấp thuận',
    TU_CHOI: 'Từ chối',
    DANG_THU_LY: 'Đang thụ lý',
    CHUA_THU_LY: 'Chưa thụ lý',
};

export const KET_QUA_HO_SO_VALUE = {
    CHAP_THUAN: 'CHAP_THUAN',
    TU_CHOI: 'TU_CHOI',
    DANG_THU_LY: 'DANG_THU_LY',
    CHUA_THU_LY: 'CHUA_THU_LY',
};

export const BUOC_XU_LY = {
    PC: 'Hồ sơ mới',
    TN: 'Tiếp nhận',
    DX_CAP_TT: 'Đề xuất cấp thị thực',
    DX_TC_TT: 'Đề xuất từ chối thị thực',
    XL_TCT: 'Xử lý theo chỉ thị',
    YC_BS: 'Yêu cầu bổ sung',
    TN_BS: 'Tiếp nhận bổ sung',
    XYK: 'Xin ý kiến',
    DA_KY: 'Đã ký TT',
    CAP_TT: 'Cấp thị thực',
    TC_TT: 'Từ chối thị thực',
    HUY: 'Hủy thị thực'
}

export const DURATION_TARIFF = [
    {label: 'Tối đa 90 ngày', value: 1},
    {label: 'Từ 90 - 180 ngày', value: 2},
    {label: 'Từ 180 ngày đến 1 năm', value: 3},
    {label: 'Trên 1 năm - 2 năm', value: 4},
    {label: 'Trên 2 - 5 năm', value: 5},
]

export const DURATION_TARIFF_KEY_VAL = {
    MAX_90_DAY: 1,
    FROM_90_180_DAY: 2,
    FROM_180_1_YEAR: 3,
    FROM_1_2_YEAR: 4,
    ABOVE_2_5_YEAR: 5,
}

export const STATUS_SHOW = {
    INACTIVE: 0,
    ACTIVE: 1,
}

export const TYPE_POSTS = [
    {noiDung: 'Banner', loai: 0, children: [], maBaiViet: 'Banner', isParent: true},
    {noiDung: 'Tổng quan', loai: 1, children: [], maBaiViet: 'Tổng quan', isParent: true},
    {noiDung: 'Điều kiện', loai: 2, children: [], maBaiViet: 'Điều kiện', isParent: true},
    {noiDung: 'Quyền và nghĩa vụ', loai: 3, children: [], maBaiViet: 'Quyền và nghĩa vụ', isParent: true},
    {noiDung: 'Vấn đề liên quan', loai: 4, children: [], maBaiViet: 'Vấn đề liên quan', isParent: true},
    {noiDung: 'Lưu ý thanh toán (Bước 3 khai báo)', loai: 5, children: [], maBaiViet: 'Lưu ý', isParent: true},
]

export const TYPE_NOTE_DECLARATION = [
    {noiDung: 'VĂN BẢN', type: 1, children: [], maLuuY: 'VĂN BẢN', isParent: true},
    {noiDung: 'CHECKBOX', type: 2, children: [], maLuuY: 'CHECKBOX', isParent: true},
]

export const POPUP_TYPE={
    ds_ng_nuoc_ngoai: 1,
    quyen_nghia_vu: 2,
    dk_nhap_canh: 3,
    ds_cua_khau: 4,
    tt_mien_thi_thuc: 5,
    qd_tam_tru: 6,
}

export const KY_SO_STATUS = [
    {label: 'Đã ký số', value: 1},
    {label: 'Chưa ký số', value: 0}
]

export const PROFILES_STATUS = [
    {label: 'Chấp thuận thị thực', value: 1},
    {label: 'Hủy thị thực', value: 2}
]

export const PROFILES_STATUS_VALUE = {
    CHAP_THUAN: 1,
    HUY: 2
}

export const LANGUAGE_TYPE = {
    ENGLISH: 'en',
    VIETNAMESE: 'vi',
    CHINA: 'cn',
    JAPAN: 'jp',
}
